import './config/public-path'

import * as React from 'react'
import { render } from 'react-dom'
import App from './App'

render(
  (
    <App/>
  ),
  document.getElementById('root'),
)

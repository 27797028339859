import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export default function FormStatusAfterPay({status = true, message, classes}) {
    return (
        <Grid className={classes.payResult} container justify="center" alignItems="center" direction="column" spacing={32}>
            <Grid item>
                <img src={require('../components/icons/' + (status ? 'success' : 'error') + '.png')} alt=""/>
            </Grid>
            <Grid item>
                <Typography variant="h3">
                    { message }
                </Typography>
            </Grid>
        </Grid>
    )
}

function formPostRequest(action, fields) {
    const form = document.createElement('form')
    form.action = action
    form.method = 'POST'
    Object.keys(fields).map((field) => {
        const input = document.createElement('input')
        input.setAttribute('name', field)
        input.setAttribute('value', fields[field])
        form.appendChild(input)
    })
    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
}

function findGetParameter(parameterName) {
    let result = null,
        tmp = []
    location.search
        .substr(1)
        .split('&')
        .forEach((item) => {
            tmp = item.split('=')
            if (tmp[0] === parameterName) {
                result = decodeURIComponent(tmp[1])
            }
        })
    return result
}

export {
    formPostRequest,
    findGetParameter,
}

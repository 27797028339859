import { createMuiTheme } from '@material-ui/core/styles'

declare module '@material-ui/core/styles/createMuiTheme' {
  interface ThemeOptions {
    container?: {
      width?: string
      maxWidth?: string
      marginRight: string | number
      marginLeft: string | number
      paddingRight: string | number
      paddingLeft: string | number,
    }
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {
    brandGradient?: {
      backgroundImage?: string,
      backgroundColor?: string,
    },
    dark?: string
    light?: string
    lightGrey?: string
    darkGrey?: string
    paleGrey?: string
    slateGrey?: string
    greenBlue?: string
    simpleGrey?: string
    whiteGrey?: string
    darkGreen?: string
    coolGrey?: string
    blackTwo?: string
    whiteThree?: string
    steel?: string
  }
}

const fontFamily = '"PT Sans Pro", "PT Sans", sans-serif'

const theme = createMuiTheme({
  palette: {
    dark: '#232A2D',
    light: '#ffffff',
    darkGreen: '#48ab9d',
    lightGrey: '#F4F4F6',
    darkGrey: '#d3d3d3',
    paleGrey: '#e5e5e5',
    slateGrey: '#62666a',
    simpleGrey: '#949695',
    whiteGrey: '#ededed',
    greenBlue: '#00ae9d',
    coolGrey: '#a1aab6',
    blackTwo: '#121212',
    whiteThree: '#eeeeee',
    steel: '#758498',
    primary: {
      light: '#e0f3f1',
      main: '#48ab9d',
      dark: '#2f877b',
      contrastText: '#ffffff',
    },
    background: {
      default: '#e5e5e5',
    },
    brandGradient: {
      backgroundColor: '#48ab9d',
      backgroundImage: 'linear-gradient(to bottom, #48ab9d, #83cbc1)',
    },
  },
  shape: {
    borderRadius: 3,
  },
  // @ts-ignore
  shadows: [
    'none',
    '0px 3px 17px 0px rgba(0, 0, 0, 0.06)',
  ],
  container: {
    width: '100%',
    maxWidth: '1124px',
    paddingRight: 24,
    paddingLeft: 24,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  typography: {
    fontFamily,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    h1: {
      fontFamily,
      fontSize: '50px',
      lineHeight: 1.3,
      color: 'rgba(0,0,0,.60)',
    },
    h2: {
      fontFamily,
    },
    h3: {
      fontFamily,
      fontSize: '40px',
    },
    h4: {
      fontFamily,
      fontSize: '30px',
    },
    h5: {
      fontSize: '24px',
      fontFamily,
    },
    h6: {
      fontFamily,
      fontSize: '13px',
    },
    body1: {
      fontFamily,
      fontSize: '18px',
    },
    body2: {
      fontFamily,
      fontSize: '16px',
    },
    button: {
      textTransform: 'none',
    },
  },
})

export default theme

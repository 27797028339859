import React from 'react'
import axios from 'axios'

import 'normalize.css'
import './fonts.css'
import { WithStyles } from '@material-ui/core'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import theme from './theme'
import styles from './App.styles'
import Logo from './components/Logo'

import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CloseIcon from './icons/CloseIcon'

import FormStatusAfterPay from './components/FormStatusAfterPay'

import { CardNumberMask, ExpirationDayMask } from './utils/card'
import { findGetParameter } from './utils/url'

const baseUrl = process.env.BACKEND_URL

declare global {
  interface Window {
    cp: any,
  }
}

class App extends React.Component<WithStyles<typeof styles>> {

  public componentDidMount() {
    axios.get('https://api.ipify.org/?format=json')
      .then((response) => {
        this.setState({ ipAddress: response.data.ip })
      })
  }

  public state = {
    amount: Number(findGetParameter('amount')),
    currency: findGetParameter('currency'),
    description: findGetParameter('description'),
    publicId: findGetParameter('publicId'),
    invoiceId: findGetParameter('invoiceId'),
    cardHolderName: '',
    cardNumber: '',
    expirationDay: '',
    cvv: '',
    ipAddress: '',
    disabled: false,
    copayId: '',
    isSuccess: null,
    errorMessage: 'Error!\n Please, try again later!',
  }

  private handleInputChange = (e) => {
    if ( !(e.target.name === 'cvv' && e.target.value.length > 3) ) {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  private handleSubmitForm = () => {
    this.setState({ disabled: true })

    const checkout = new window.cp.Checkout(this.state.publicId)

    const result = checkout.createCryptogramPacket({
      cardNumber: this.state.cardNumber,
      expDateMonth: this.state.expirationDay.substring(0, 2),
      expDateYear: this.state.expirationDay.substring(3, 5),
      cvv: this.state.cvv,
      name: this.state.cardHolderName,
    })

    if (result.success) {
      axios.post(`${baseUrl}/cards/charge`, {
        amount: this.state.amount,
        currency: this.state.currency,
        ip_address: this.state.ipAddress,
        name: this.state.cardHolderName,
        card_cryptogram_packet: result.packet,
        public_id: this.state.publicId,
        invoice_id: this.state.invoiceId,
      })
      .then((response) => {
        if (response.data.error) {
          this.setState({
            isSuccess: false,
            errorMessage: 'Server error. Please, try again later.',
          })
        } else if (response.data.result.Success === true) {
          if (process.env.BACKEND_URL !== 'production') {
            console.log('Successful payment')
          }
          this.setState({
            isSuccess: true,
          })
        } else {
          if (response.data.result.Message) {
            this.setState({
              isSuccess: false,
              errorMessage: response.data.result.Message,
            })
          } else {
            try {
              // 3 ds
              window.cp.Loader.initWidget(
                  {
                    AcsUrl: response.data.result.Model.AcsUrl,
                    MD: response.data.result.Model.TransactionId.toString(),
                    PaReq:  response.data.result.Model.PaReq,
                    TermUrl: response.data.result.TermUrl,
                    onSuccess: () => {
                      this.setState({ isSuccess: true })
                    },
                    onError: () => {
                      this.setState({ isSuccess: false })
                    },
                  },
                  true,
              )
            } catch (e) {
              console.error(e)
            }
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
    }
  }

  private handleCloseIframe = () => {
    if (parent && this.state.isSuccess !== null) {
      parent.postMessage({id: 'cp.pay.' + (this.state.isSuccess ? 'success' : 'error')}, '*')
    }
  }

  private isInitialState() {
    return this.state.isSuccess === null
  }

  private isSuccess() {
    return this.state.isSuccess === true
  }

  private isError() {
    return this.state.isSuccess === false
  }

  private afterPayMessage() {
    return this.isSuccess() ? 'Your operation is successfully processed!' : this.state.errorMessage;
  }

  public render() {

    const { classes } = this.props

    const currencyNames = {
      usd: '$',
      eur: '€',
    }

    return (
      <MuiThemeProvider theme={ theme }>
        <Grid container direction="column" justify="center" alignItems="stretch" className={ classes.app }>

          { this.isInitialState() && (
            <CloseIcon
              className={ classes.closeIcon }
              onClick={ this.handleCloseIframe }
            />
          ) }

          <Grid item className={ classes.header }>
            <Logo className={ classes.headerLogo } />

            { this.isInitialState() && (
              <>
                <Typography variant="body2" className={ classes.headerText }>
                  { this.state.description ? this.state.description : 'Top up balance' }
                </Typography>

                <Typography variant="h2" className={ classes.price }>
                  { currencyNames[this.state.currency] }{ this.state.amount }
                </Typography>
              </>
            ) }
          </Grid>

          { this.isInitialState() && (
            <>
              <Grid item className={ classes.formItem }>
                <Typography variant="body2" className={ classes.formLabel }>
                  Card Number
                </Typography>
                <TextField
                  id="cardNumber"
                  name="cardNumber"
                  type="text"
                  label=""
                  className={ classes.formInput }
                  value={ this.state.cardNumber }
                  onChange={ this.handleInputChange }
                  fullWidth
                  InputProps={{
                    inputComponent: CardNumberMask as any,
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={ require('./components/icons/visa.png') } />
                        <img className={ classes.formInputImage } src={ require('./components/icons/mastercard.png') } />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item className={ classes.formItem }>
                <Typography variant="body2" className={ classes.formLabel }>
                  Cardholder Name
                </Typography>
                <TextField
                    id="cardHolderName"
                    name="cardHolderName"
                    type="text"
                    label=""
                    className={ classes.formInput }
                    value={ this.state.cardHolderName }
                    onChange={ this.handleInputChange }
                    fullWidth
                />
              </Grid>

              <Grid item className={ classes.formItem }>
                <Grid container>
                  <Grid item className={ classes.formItemExpiration }>
                    <Typography variant="body2" className={ classes.formLabel }>
                      Expiration Day
                    </Typography>
                    <TextField
                      id="expirationDay"
                      name="expirationDay"
                      value={ this.state.expirationDay }
                      onChange={ this.handleInputChange }
                      label=""
                      className={ classes.formInput }
                      InputProps={{
                        inputComponent: ExpirationDayMask as any,
                      }}
                    />
                  </Grid>

                  <Grid item className={ classes.formItemCvc }>
                    <Typography variant="body2" className={ classes.formLabel }>
                      CVC/CVV
                    </Typography>
                    <TextField
                      id="cvv"
                      name="cvv"
                      value={ this.state.cvv }
                      onChange={ this.handleInputChange }
                      label=""
                      className={ classes.formInput }
                      type="password"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) }

          {
            !this.isInitialState() && <FormStatusAfterPay
                status={this.isSuccess()}
                message={this.afterPayMessage()}
                classes={classes}
            />
          }

          <Grid item className={ classes.buttonToBuy }>
            { this.isInitialState() && (
              <Button
                variant="contained"
                fullWidth
                onClick={ this.handleSubmitForm }
                disabled={ this.state.disabled || !this.state.cardNumber || !this.state.expirationDay || !this.state.cvv }
              >
                Buy
              </Button>
            ) }

            { !this.isInitialState() && (
              <Button
                variant="contained"
                fullWidth
                onClick={ this.handleCloseIframe }
              >
                Back to site
              </Button>
            ) }
          </Grid>

          <Grid item className={ classes.copyright }>
            <Grid container justify="center">
              <Grid item>
                <img src={ require('./components/icons/copyright.png') } />
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={ classes.footer }>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <img src={ require('./components/icons/pci_dss.png') } />
              </Grid>
              <Grid item>
                <img src={ require('./components/icons/verified_by_visa.png') } />
              </Grid>
              <Grid item>
                <img src={ require('./components/icons/mastercard_securecode.png') } />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </MuiThemeProvider>
    )

  }

}

export default withStyles(styles)(App)

import React from 'react'
import MaskedInput from 'react-text-mask'

interface MaskProps {
  inputRef: (ref: HTMLInputElement | null) => void
}

function CardNumberMask(props: MaskProps) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      { ...other }
      ref={ (ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={ [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/] }
      placeholderChar={ '\u2000' }
      guide={ false }
    />
  )
}

function ExpirationDayMask(props: MaskProps) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      { ...other }
      ref={ (ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={ [/\d/, /\d/, '/', /\d/, /\d/] }
      placeholderChar={ '\u2000' }
      guide={ false }
    />
  )
}

export {
    CardNumberMask,
    ExpirationDayMask,
}

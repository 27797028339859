/* eslint-disable no-undef */

const parseUri = (uri) => {
    const parser = document.createElement('a');
    parser.href = uri;

    return {
        protocol: parser.protocol,
        hostname: parser.hostname,
        port: parser.port,
        path: parser.hostname + parser.pathname,
        pathname: parser.pathname,
        search: parser.search,
        query: parseQueryString(parser.search.replace(/^\?/, '')),
        hash: parser.hash,
        host: parser.host
    };
};

const parseQueryString = (args) => {
    const result = {};

    args
        .split('&')
        .forEach(function (arg) {
            const item = arg.split('=');
            if (item[0].length !== 0 && item[1]) {
                result[item[0]] = item[1];
            }
        });

    return result;
};

const { protocol, hostname, port } = parseUri(document.currentScript.src);

__webpack_public_path__ = `${protocol}//${hostname}${port ? ':' + port : ''}/`;

import React from 'react'
import classNames from 'classnames'
import { WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './Logo.styles'

interface LogoProps {
  className?: string
}

function Logo(props: WithStyles & LogoProps) {

  const { classes, className: parentClassName } = props

  return (
    <>
      <img className={ parentClassName } src={ require('./logo.png') } alt="Logo" />
    </>
  )
}

export default withStyles(styles)(Logo)

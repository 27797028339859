import { createStyles } from '@material-ui/core/styles'

export default function styles(theme) {
  return createStyles({
    '@global': {
      'html, body': {
        minHeight: '100vh',
      },
      '#root': {
        minHeight: '100vh',
        backgroundColor: '#e5e5e5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    app: {
      fontFamily: '"PT Sans Pro", "PT Sans", sans-serif',
      maxWidth: 620,
      padding: `${theme.spacing.unit * 5}px`,
      background: '#fff',
      color: '#333',
      fontSize: '16px',
      borderRadius: 10,
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.06)',
      position: 'relative',
    },

    closeIcon: {
      position: 'absolute',
      top: theme.spacing.unit * 3,
      right: theme.spacing.unit * 3,
      cursor: 'pointer',
    },

    header: {
      paddingBottom: 60,
      textAlign: 'center',
    },

    headerLogo: {
      marginBottom: theme.spacing.unit * 5,
      width: 180,
    },

    headerText: {
      marginBottom: 20,
      color: '#333',
      fontWeight: 300,
      lineHeight: '16px',
    },

    price: {
      fontWeight: 600,
      letterSpacing: '1px',
      fontSize: 30,
    },

    formItem: {
      marginBottom: theme.spacing.unit * 5,
      minWidth: '100%',
    },

    formItemExpiration: {
      maxWidth: 100,
    },

    formItemCvc: {
      maxWidth: 100,
      marginLeft: 20,
    },

    formInput: {
      '& input': {
        paddingBottom: 20,
        paddingTop: 18,
        fontSize: 18,
        color: '#333',
        fontWeight: 600,
      },
    },

    formInputImage: {
      marginLeft: 27,
    },

    formLabel: {
      fontSize: 14,
      color: '#333',
      opacity: .5,
      lineHeight: '14px',
      fontWeight: 300,
    },

    buttonToBuy: {
      minWidth: '100%',
      marginBottom: 20,
      '& button': {
        background: '#fff',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
      },
      '& span': {
        paddingTop: 12,
        paddingBottom: 12,
        fontSize: 18,
        color: '#333',
      },
    },

    copyright: {
      marginBottom: 40,
    },

    footer: {
      '& div:not(:last-child)': {
        marginRight: 60,
      },
    },

    payResult: {
      maxWidth: '90%',
      textAlign: 'center',
      margin: '0 auto 80px',
      '& img': {
        maxWidth: 68,
      },
      '& h3': {
        fontSize: 30,
        color: '#333',
        lineHeight: '39px',
      },
    },

  })
}
